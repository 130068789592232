import React, { useState, useEffect, useRef } from 'react';
import { Container, AppBar, Button, Toolbar, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, styled } from '@mui/material';
import { fetchProducts, fetchMoreProducts } from './api';
import chroma from 'chroma-js';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

const FlameImage = styled('div')({
  width: '2em',
  height: '2em',
  borderRadius: '50%',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
});

const GradientAppBar = styled(AppBar)({
  background: '#ffffff', // 흰색 배경으로 변경
  borderBottom: '5px solid #ffd700', // 경계선 추가, 여기서는 3픽셀 두께의 주황색(#ffa500) 선을 사용했습니다.
});

const parseScores = (aiText) => {
  const regex = /(.*?): (\d(?:\.\d{1,2})?)\/5/g;
  let match;
  const scores = [];

  while ((match = regex.exec(aiText)) !== null) {
    const category = match[1].replace(/[-\s]/g, '');
    scores.push({ category, value: parseFloat(match[2]) });
  }

  return scores;
};

const handleImageError = (e) => {
  e.target.onerror = null;
  e.target.src = '/image/logo_old.png';
  e.target.style.filter = 'grayscale(100%) blur(1px)'; // 흑백 필터 적용
};


function App() {
  const [products, setProducts] = useState([]);
  const [lastProductId, setLastProductId] = useState(0);
  const [expandedRows, setExpandedRows] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const tableContainerRef = useRef(null); // Add a ref for the TableContainer

  // 구글 애널리틱스
useEffect(() => {
  function initializeAnalytics() {
    ReactGA.initialize('G-X749L57KZY');
    ReactGA.send('pageview', window.location.pathname + window.location.search);
  }
  
  initializeAnalytics();
}, []); // 빈 배열을 넘겨주면 컴포넌트가 처음 마운트될 때만 실행됨

  // 웹 앱 내부 함수
  const checkForKeyword = (keyword) => {
    const productNames = products.map((product) => product.product_name);
  
    for (const productName of productNames) {
      if (productName.includes(keyword)) {
        console.log('Keyword detected:', keyword); // 콘솔에 키워드를 출력합니다.
        if (window.ReactNativeWebView) { // React Native 환경인 경우에만 메시지를 전송합니다.
          window.ReactNativeWebView.postMessage(keyword);
        }
        break;
      }
    }
  };

  useEffect(() => {
    const getProducts = async () => {
      setIsLoading(true);
      const { data } = await fetchProducts();
      setProducts(data);
      setLastProductId(data[data.length - 1].id);
      setIsLoading(false);
  
      //checkForKeyword('네이버'); // 키워드 검사를 추가합니다.
    };

    getProducts();

    // Add the scroll event listener when the component mounts
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      // Remove the scroll event listener when the component unmounts
      if (tableContainer) {
        tableContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isScrollEnd) {
      loadMore();
      setIsScrollEnd(false);
    }
  }, [isScrollEnd]);

  const handleScroll = () => {
    const tableContainer = tableContainerRef.current;
    if (!tableContainer) return;

    const scrollTop = tableContainer.scrollTop;
    const clientHeight = tableContainer.clientHeight;
    const scrollHeight = tableContainer.scrollHeight;
    const threshold = 100; // A threshold value to trigger fetching more data before reaching the end of the page

    if (scrollTop + clientHeight + threshold >= scrollHeight) {
      setIsScrollEnd(true);
    }
  };

  const loadMore = async () => {
    setIsLoading(true);
    const { data } = await fetchMoreProducts(lastProductId);
  
    // Check if there is more data and if the last element has an 'id' property
    if (data.length === 0 || !data[data.length - 1].hasOwnProperty('id')) {
      setIsLoading(false);
      return; // Stop execution if there's no more data or the last element doesn't have an 'id' property
    }
  
    // Filter out any products that are already in the existing products array
    const uniqueData = data.filter((newProduct) => !products.some((existingProduct) => existingProduct.id === newProduct.id));
  
    setProducts([...products, ...uniqueData]);
    setLastProductId(uniqueData[uniqueData.length - 1].id);
    setIsLoading(false);
  
    checkForKeyword('네이버'); // 키워드 검사를 추가합니다.
  };
  

  const handleRowClick = (productId) => {
    setExpandedRows(prevExpandedRows => ({
      ...prevExpandedRows,
      [productId]: !prevExpandedRows[productId]
    }));
  };

  const handleProductNameClick = (e, link) => {
    e.stopPropagation();
    window.open(link, '_blank');
  };

  const scoreToColor = (score) => {
    const startColor = '#ffffff'; // 투명
    const endColor = '#ffd700'; // 노란색
    return chroma.scale([startColor, endColor])(score / 5).hex();
  };

  const formatPrice = (price, currency) => {
    let formattedPrice = price;
    if (currency === 'KRW') {
      formattedPrice = Math.floor(price);
    }

    return formattedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { timeZone: 'Asia/Seoul', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Intl.DateTimeFormat('ko-KR', options).format(date);
  };

  const handleRefresh = async () => {
  const tableContainer = tableContainerRef.current;
  if (tableContainer) {
    tableContainer.scrollTop = 0;
  }

  setIsLoading(true);
  const { data } = await fetchProducts();
  setProducts(data);
  setLastProductId(data[data.length - 1].id);
  setIsLoading(false);
};


  return (
    <Container style={{ paddingTop: '70px' }}>
      <Helmet>
        <title>AI Hotdeal</title>
        <link rel="icon" href="./coin-dollar.svg" type="image/svg+xml" />
      </Helmet>
      <GradientAppBar position="fixed">
      <Toolbar style={{ minHeight: '80px', alignItems: 'flex-end' }}>
        <img src="/image/logo.png" alt="Logo" style={{ marginRight: '16px', height: '40px', marginBottom: '10px'  }} />
      </Toolbar>
      </GradientAppBar>

      <TableContainer ref={tableContainerRef} style={{ maxHeight: '80vh', overflow: 'auto', marginTop: '15px' }}>
        <Table stickyHeader>
          <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#616161' }}>
            </TableHead>
          <TableBody>
            {products.map((product) => (
              <React.Fragment key={`fragment-${product.id}`}>
                <TableRow key={product.id} onClick={() => handleRowClick(product.id)} style={{ height: 'auto' }}>
                <TableCell
                  valign="top"
                  onClick={(e) => handleProductNameClick(e, product.post_link)}
                  style={{ cursor: 'pointer', paddingRight: '15px', paddingLeft: '15px'}} // paddingRight과 paddingLeft 값을 조정
                >
                <div style={{ display: 'flex', alignItems: 'center' }}> {/* 이 div를 추가합니다. */}
                  <img
                    src={`itemImage/${product.img_file_name}`}
                    onError={handleImageError}
                    style={{ marginRight: '5px', height: '50px' }}
                  />
                  <strong>{product.product_name}</strong> {/* 텍스트를 강조합니다. */}
                </div> {/* div 태그를 닫습니다. */}
              </TableCell>
               </TableRow>
               <TableRow>
               <TableCell style={{ paddingBottom: 0, paddingTop: 0, maxWidth: '100px', backgroundColor: '#F5F5F5' }} colSpan={8}>
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <div style={{ margin: '5px 0', whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'center', paddingLeft: '10px' }}> {/* 상하 마진을 8px로 변경 */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '1.5em',
                        height: '1.5em',
                        borderRadius: '50%',
                        backgroundColor: chroma.scale(['#ffffff', '#ff0000']).domain([0, 10])(product.ai_score).alpha(0.5).hex(),
                        color: 'white',
                        //fontWeight: 'bold',
                        fontSize: '1.3em',
                        marginRight: '10px',
                        flexShrink: 0,
                      }}
                    >
                      {Math.round(product.ai_score)}
                    </div>
                    <div>
                      {product.ai_text && product.ai_text.split('\n').map((line, index) => (
                        line.startsWith('종합') ? (
                          <span key={index} style={{ color: '#DAA520', fontWeight: 'bold', backgroundColor: '#333', borderRadius: '4px', padding: '2px 4px' }}>{line}<br /></span>
                        ) : (
                          <span key={index}>{line}<br /></span>
                        )
                      ))}
                    </div>
                  </div>
                </Collapse>
              </TableCell>
              </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
        <Button
          variant="text"
          style={{ textTransform: 'none', color: '#B0B0B0' }}
          onClick={handleRefresh}
        >
          refresh
        </Button>
      </div>
    </Container>
  );
}

export default App;

